import Vue from 'vue';
import './resources/firebase';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import router from './routes/router';
import helpers from './helpers/helpers';
import store from './store/store';
import { auth } from './resources/firebase';

Vue.config.productionTip = false;

const helperPlugin = {
	install() {
		Vue.helpers = helpers;
		Vue.store = store;
		Vue.prototype.$store = store;
		Vue.prototype.$helpers = helpers;
	},
};

Vue.use(helperPlugin);
Vue.use(DashboardPlugin);

auth.onAuthStateChanged((user) => {
	if (store.state.organization) {
		handleUser(user);
	} else {
		store
			.dispatch('getOrgData')
			.then(() => {
				handleUser(user);
			})
			.catch((error) => {
				handleUser(user);
			});
	}
});

function handleUser(user) {
	if (user) {
		store
			.dispatch('getUserData', user)
			.then(() => {
				store
					.dispatch('getAppUserData', store.state.organizationUser)
					.then(() => {
						openApp(router, App);
					})
					.catch((error) => {
						store
							.dispatch('logOut')
							.then(() => {
								openApp(router, App);
							})
							.catch((error) => {
								//console.log(error);
								openApp(router, App);
							});
					});
			})
			.catch((error) => {
				store
					.dispatch('logOut')
					.then(() => {
						openApp(router, App);
					})
					.catch((error) => {
						//console.log(error);
						openApp(router, App);
					});
			});
	} else {
		store.commit('setUser', null);
		openApp(router, App);
	}
}

function openApp(router, App) {
	new Vue({
		el: '#app',
		router,
		render: (h) => h(App),
	});
}
