<template>
	<div>
		
			<DashboardHeaderVue
				:title="$route.name"
				:buttonItems="buttonItems"
				@click="handleHeaderClick"
			/>

			<div
				class="container-fluid mt--6"
				:aria-hidden="isLoading ? 'true' : null"
			>
				<div class="row">
					<div class="col">
						<div class="card">
							<b-list-group>
								<b-list-group-item v-for="notification in allNotifications">
									<b-row>
										<b-col class="pl-3 pr-1 pb-0" cols="auto">
											<!-- Avatar -->
											<b-img
												alt="Image placeholder"
												:src="organization.appLogo"
												class="avatar"
												rounded="circle"
											/>
										</b-col>
										<b-col class="pl-2 pr-0 pb-0">
											<div class="d-flex align-items-center">
												<div>
													<h4 class="mb-0 mr-1 text-sm">
														{{ organization.appName }}
													</h4>
												</div>
												<div class="text-right text-muted">
													<small>
														· {{ notification.notificationTimestamp }}</small
													>
												</div>
											</div>
											<p class="text-sm mb-0">{{ notification.message }}</p>
										</b-col>
										<b-col class="px-2 pb-0" cols="auto">
											<b-dropdown
												:id="notification.messageId"
												variant="link"
												size="sm"
												class="m-2"
												dropleft
												no-caret
											>
												<template v-slot:button-content>
													<span class="btn btn-sm btn-icon-only text-light">
														<i class="fas fa-ellipsis-v mt-2"></i>
													</span>
												</template>
												<b-dropdown-item-button
													v-on:click="editNotification(notification)"
													>Edit</b-dropdown-item-button
												>
												<b-dropdown-item-button
													variant="danger"
													v-on:click="askToDeleteNotification(notification)"
													>Delete</b-dropdown-item-button
												>
											</b-dropdown>
										</b-col>
									</b-row>
								</b-list-group-item>
							</b-list-group>
						</div>
						<b-overlay :show="isLoading" rounded="sm" no-wrap></b-overlay>
					</div>
				</div>
			</div>
		</b-overlay>
		<NewNotification
			:headerTitle="buttonItems[0].title"
			:showModal="showModal"
			@close="showModal = false"
			@newNotification="onNewNotification"
			@sendNotification="sendNotification"
		/>
		<QuestionModal :show="showQuestionModal" :question="modalQuestion" @close="showQuestionModal = false" @yesClicked="deletetNotification" @noClicked="notification == null"/>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import Modal from '../../../components/Modals/Modal.vue';
import NewNotification from '../../mainForms/NewNotification.vue';
import { mapActions, mapGetters } from 'vuex';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, setDoc } from '@firebase/firestore';

export default {
	name: 'notifications',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: {
    DashboardHeaderVue,
    Modal,
    NewNotification,
    QuestionModal
},
	data() {
		return {
			isLoading: true,
			allNotifications: [],
			showModal: false,
			showQuestionModal: false,
			modalQuestion: 'Are you sure you want to delete this notification?',
			notification: null,
			buttonItems: [
				{
					index: 0,
					title: 'New Notification',
					type: 'primary',
					icon: 'fas fa-plus',
					link: 'google.com',
				},
			],
		};
	},
	methods: {
		
		...mapActions(['showNotification']),
		getNotifications() {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'notifications');
			getDocs(firestoreRef).then((snapshot) => {
				if (!snapshot.empty) {
					snapshot.forEach((item) => {
						var notification = item.data()
						var data = {
							messageId: notification.messageId,
							message: notification.message,
							notificationTimestamp: this.$helpers.timeSince(
								notification.uploadTimestamp.toDate()
							),
							timestamp: notification.uploadTimestamp,
						};
						this.allNotifications.push(data);
					});
					this.allNotifications.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1)
					this.isLoading = false;
				} else {
					this.isLoading = false;
				}
			}).catch((error) => {
				this.showNotification({
					type: 'danger',
					message: `${error.message}`
				})
			});
		},
		sendNotification(message){
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'notifications');
			const newDatabaseRef = doc(firestoreRef)
			const messageId = newDatabaseRef.id;
			const notification = {
				message: message,
				messageId: messageId,
				uploadTimestamp: new Date(),
			};
			setDoc(newDatabaseRef, notification).then(() => {
						this.isLoading = false;
						this.onNewNotification(notification)
						this.showModal = false
					})
					.catch((error) => {
						this.showNotification({
							type: 'danger',
							message: `${error.message}`,
						});
					});
					
		},
		onNewNotification(notification){
			
			this.isLoading = true
			var data = {
							messageId: notification.messageId,
							message: notification.message,
							notificationTimestamp: this.$helpers.timeSince(
								new Date(notification.timestamp)
							),
							timestamp: notification.timestamp,
						};
			this.allNotifications.push(data)
			this.allNotifications.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1)
			this.isLoading = false;
			this.showNotification({
				type: 'success',
				message: 'Notification Sent.'
			})
			
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.showModal = !this.showModal;
			}
		},
		editNotification(notification) {
			console.log(`Moses edit on ${notification.messageId}`);
		},
		askToDeleteNotification(notification){
			this.notification = notification
			this.showQuestionModal = true
			return
		},
		deletetNotification() {
			if (this.notification){
				this.isLoading = true
				const firestore = getOrganizationFirestore(this.organization);
				const firestoreRef = doc(firestore, `notifications/${this.notification.messageId}`)

				deleteDoc(firestoreRef).then(() => {
				this.allNotifications = this.allNotifications.filter((item) => item.messageId != this.notification.messageId)
				this.isLoading = false
				this.showNotification({
					type: 'success',
					message: `Notification Deleted.`
				})
			}).catch((error) => {
				this.isLoading = false
				this.showNotification({
					type: 'danger',
					message: `${error.message}`
				})
			})
			}
		},
	},
	mounted() {
		this.getNotifications();
	},
};
</script>
