<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<MembersTable
						:isLoading="isLoading"
						:tableData="allUsers"
						:tableHeaders="userTitles"
						@resendClicked="resendClicked"
						@newPassClicked="newPassClicked"
						@deleteClicked="deleteClicked"
					/>
				</div>
			</div>
		</div>
		<NewTempMember
			:showModal="showModal"
			@close="showModal = false"
			@newUser="onNewUser"
		/>
		<AdminUploadFile
			:showModal="showFileModal"
			@close="showFileModal = false"
			@addedFile="addedFile"
		/>
	</div>
</template>
<script>
import PSPDFKit from 'pspdfkit'
import axios from 'axios'
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import {
	getOrganizationDatabase,
	getOrganizationFirestore,
} from '../../../resources/organizationFirebase';
import { mapActions, mapGetters } from 'vuex';
import MembersTable from '../../Components/MemberViews/MembersTable.vue';
import {
	collection,
	deleteField,
	doc,
	getDocs,
	setDoc,
	updateDoc,
	deleteDoc,
} from '@firebase/firestore';
import NewTempMember from '../../mainForms/NewTempMember.vue';
import AdminUploadFile from '../../mainForms/AdminUploadFile.vue';
import csvtojson from 'csvtojson';

export default {
	name: 'shop',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeaderVue,
		MembersTable,
		NewTempMember,
		AdminUploadFile,
	},
	data() {
		return {
			isLoading: true,
			showModal: false,
			showFileModal: false,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [
						{
							index: 0,
							title: 'New User',
						},
						{
							index: 1,
							title: 'Upload Users',
						},
					],
				},
			],
			activeWaiverId: null,
			allUsers: [],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.firstName + item.lastName;
					},
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true,
					tdClass: 'align-middle',
				},
				{
					key: 'tempPswrd',
					label: 'Temporary Password',
					tdClass: 'align-middle',
				},
				{
					key: 'uid',
					label: 'Unique Id',
					tdClass: 'align-middle',
				},
				{
					key: 'emailTemplate',
					label: 'Email Template',
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return value;
					},
				},
				{
					key: 'actions',
					tdClass: 'align-middle',
				},
			],
		};
	},
	watch: {
		isLoading(val) {
			this.buttonItems[0].isDisabled = val;
		},
	},
	methods: {
		...mapActions(['showNotification']),
		childClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.showModal = true;
			} else if (buttonItem.index == 1) {
				this.showFileModal = true;
			}
		},
		getUsers() {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'tempUsers');
			getDocs(firestoreRef).then((snapshot) => {
				if (!snapshot.empty) {
					snapshot.forEach((item) => {
						var user = item.data();
						this.allUsers.push(user);
						this.allUsers.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
					});
					this.isLoading = false;
				} else {
					this.isLoading = false;
				}
			});
		},
		onNewUser(userData) {
			this.allUsers.push(userData);
			this.showNotification({
				type: 'success',
				message: `${userData.firstName} has been added.`,
			});
		},
		resendClicked(item) {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = doc(firestore, `tempUsers/${item.uid}`);
			updateDoc(firestoreRef, {
				emailSent: deleteField(),
			}).then(() => {
				this.showNotification({
					type: 'success',
					message: `Email Reseent To: ${item.firstName} ${item.lastName}`,
				});
			});
		},
		newPassClicked(item) {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = doc(firestore, `tempUsers/${item.uid}`);
			const newPasswordDigits = Array.apply(null, { length: 1 || 100 }).map(
				function () {
					return Math.floor(Math.random() * Math.pow(10, 2) + Math.pow(10, 2))
						.toString()
						.slice(-2);
				}
			);
			updateDoc(firestoreRef, {
				tempPswrd: `Pass${newPasswordDigits}`,
			}).then(() => {
				var user = this.allUsers.findIndex((user) => user.uid == item.uid);
				this.allUsers[user].tempPswrd = `Pass${newPasswordDigits}`;
				this.showNotification({
					type: 'success',
					message: `Password has been changed to: Pass${newPasswordDigits}`,
				});
			});
		},
		deleteClicked(item) {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = doc(firestore, `tempUsers/${item.uid}`);
			deleteDoc(firestoreRef).then(() => {
				this.allUsers = this.allUsers.filter((e) => e.uid !== item.uid);
				this.showNotification({
					type: 'success',
					message: `Pending Member has been removed.`,
				});
			});
		},
		addedFile(file) {
			this.processFile(file, (result) => {
				this.handleFileResult(result);
			});
		},
		processFile(file, callback) {
			const filereader = new FileReader();
			filereader.onload = function (e) {
				const text = e.target.result;
				callback(text);
			};
			filereader.readAsText(file);
		},
		handleFileResult(text) {
			this.showFileModal = false;
			csvtojson()
				.fromString(text)
				.then((result) => {
					if (result.length > 205) {
						this.showNotification({
							type: 'danger',
							message: `Please limit to only 200 entries at a time.`,
						});
					} else {
						this.addedUsers(result);
					}
				});
		},
		addedUsers(allUsers) {
			this.isLoading = true;
			const firestore = getOrganizationFirestore(this.organization);
			const total = allUsers.length;
			var counter = 0;
			allUsers.forEach((value) => {
				const tempUsersRef = doc(collection(firestore, 'tempUsers'));
				const newDocRef = tempUsersRef.id;
				const tempPswrd = Array(10)
					.fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
					.map(function (x) {
						return x[Math.floor(Math.random() * x.length)];
					})
					.join('');
				var userData = value;
				if (userData.email) {
					userData['uid'] = newDocRef;
					userData['tempPswrd'] = tempPswrd;
				}
				const finalData = this.removeAllBlankObjects(userData);
				
				setDoc(tempUsersRef, finalData)
					.then(() => {
						console.log(
							`Successful uploaded: ${finalData.firstName} ${finalData.lastName} ${finalData.email}`
						);
						if (counter == total - 1) {
							this.isLoading = false;
							console.log('Finished With: ', total);
						}
						counter += 1;
					})
					.catch((error) => {
						console.log(
							`Error uploading: ${finalData.firstName} ${finalData.lastName} ${finalData.email}. ${error.message}`
						);
						if (counter == total - 1) {
							this.isLoading = false;
							console.log('Finished With: ', total);
						}
						counter += 1;
					});
			});
		},
		removeAllBlankObjects(detailsObj) {
			return Object.entries(detailsObj).reduce(
				(a, [k, v]) => (v ? ((a[k] = v), a) : a),
				{}
			);
		},
		async loadPDF(item) {
			if (!item.qrCodePhotoURL){
				this.showNotification({
							type: 'danger',
							message: `The QR code has not been generated, please try in a few moments.`,
						});
				return
			}
			//PSPDFKit.unload(".pdf-container");

			const instance = await PSPDFKit.load({
				headless: true,
				document: '/helpers/sample_badge.pdf',
				//licenseKey: 'pdf_live_vvruBUYLIgiohqw7swzQKHYQWp9tq4WHaIcOuPVi7PY'
			})

			const first_name_box = (await instance.search("first_name")).first().rectsOnPage.get(0);
			const last_name_box = (await instance.search("last_name")).first().rectsOnPage.get(0);
			const company_name_box = (await instance.search("company_name")).first().rectsOnPage.get(0);
			const qr_box = (await instance.search("qr_code")).first().rectsOnPage.get(0);

			const first_name = new PSPDFKit.Annotations.TextAnnotation({
				boundingBox: first_name_box,
				fontSize: 16,
				text: {
					format: "plain",
					value: item.firstName
				},
				pageIndex: 0,
				fontColor: PSPDFKit.Color.BLACK,
				horizontalAlign: "center",
				backgroundColor: PSPDFKit.Color.WHITE,
			});

			const last_name = new PSPDFKit.Annotations.TextAnnotation({
				boundingBox: last_name_box,
				fontSize: 16,
				text: {
					format: "plain",
					value: item.lastName
				},
				pageIndex: 0,
				fontColor: PSPDFKit.Color.BLACK,
				horizontalAlign: "center",
				backgroundColor: PSPDFKit.Color.WHITE,
			});

			const email = new PSPDFKit.Annotations.TextAnnotation({
				boundingBox: company_name_box,
				fontSize: 10,
				text: {
					format: "plain",
					value: item.businessName ? item.businessName : item.email
				},
				pageIndex: 0,
				fontColor: PSPDFKit.Color.BLACK,
				horizontalAlign: "center",
				backgroundColor: PSPDFKit.Color.WHITE,
			});


			const request = await axios.get(item.qrCodePhotoURL, { responseType: "blob" });
			const blob = await request.data
			const imageAttachmentId = await instance.createAttachment(blob);
			const annotation = new PSPDFKit.Annotations.ImageAnnotation({
				pageIndex: 0,
				isSignature: true,
				contentType: "image/png",
				imageAttachmentId,
				description: "QR Image",
				backgroundColor: PSPDFKit.Color.WHITE,
				boundingBox: new PSPDFKit.Geometry.Rect({
					left: qr_box.left - 33,
					top: qr_box.top - 30,
					width: 120,
					height: 120
				})
			});

			// Add the annotations to the document.
			await instance.create([first_name, last_name, email, annotation]);

			const flattenedDocument = await instance.exportPDF({ flatten: true });
			var blob2 = new Blob([flattenedDocument], { type: "application/pdf" });
			var objectUrl = URL.createObjectURL(blob2);
			window.open(objectUrl);



		},
	},
	mounted() {
		this.getUsers();
	},
};
</script>
