import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
	collection,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	query,
	where,
} from 'firebase/firestore';
import { auth, firestore } from '../resources/firebase';
import { getOrganizationFirestore } from '../resources/organizationFirebase';
import store from './store';
import { DominionInformation } from '../resources/DominionInformation';
import { getApps, initializeApp } from 'firebase/app';

const actions = {
	getConferences({ commit }) {
		return new Promise((resolve, reject) => {
			const orgsRef = collection(firestore, 'organizations');
			getDocs(orgsRef)
				.then((orgSnapshot) => {
					return resolve(orgSnapshot.docs.map((e) => e.data()));
				})
				.catch((error) => {
					reject();
				});
		});
	},
	getOrgData({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			const orgId =
				DominionInformation.orgId;
			if (orgId) {
				const orgRef = doc(firestore, 'organizations', orgId);
				getDoc(orgRef).then((organizationSnapshot) => {
					const orgData = organizationSnapshot.data();
					commit('setOrganization', orgData);
					return resolve();
				});
			} else {
				reject();
			}
		}).catch((error) => {
			reject();
		});
	},
	getUserData({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			const userRef = doc(firestore, 'users', data.uid);
			getDoc(userRef)
				.then((snapshot) => {
					const userData = snapshot.data();
					const orgId = DominionInformation.orgId
					var updatedUserData = userData;
					updatedUserData.orgId = orgId;
					commit('setOrganizationUser', updatedUserData);
					return resolve();
				})
				.catch((error) => {
					dispatch('logOut');
					reject();
				});
		});
	},
	getAppUserData({ commit }, data) {
		return new Promise((resolve, reject) => {
			if (store.state.organizationUser.appUid) {
				const userRef = doc(
					getOrganizationFirestore(),
					'users',
					store.state.organizationUser.appUid
				);
				getDoc(userRef)
					.then((snapshot) => {
						if (snapshot.exists()) {
							const userData = snapshot.data();
							commit('setUser', userData);
							if (userData.isAdmin) {
								return resolve();
							} else if (userData.businessId) {
								const businessRef = doc(
									getOrganizationFirestore(),
									'business',
									userData.businessId
								);
								getDoc(businessRef)
									.then((businessSnapshot) => {
										const businessData = businessSnapshot.data();
										commit('setBusiness', businessData);
										return resolve();
									})
									.catch((error) => {
										return reject(error.message);
									});
							} else {
								return reject('No Business User');
							}
						} else {
							const orgId = DominionInformation.orgId
							const orgRef = doc(firestore, 'organizations', orgId);
							getDoc(orgRef)
								.then((organizationSnapshot) => {
									//get other organziation ??
									const organization = organizationSnapshot.data();

									const firebaseConfig = {
										apiKey: organization.apiKey,
										authDomain: organization.authDomain,
										projectId: organization.projectId,
										databaseURL: organization.databaseURL,
										storageBucket: organization.storageBucket,
										messagingSenderId: organization.messagingSenderId,
										appId: organization.appId,
										measurementId: organization.measurementId,
									};

									var otherFirestore = getApps().find(
										(x) => x.name === organization.appName
									);

									if (!otherFirestore) {
										// Initialize Firebase
										otherFirestore = initializeApp(firebaseConfig, organization.appName);
									}

									const userCollection = collection(getOrganizationFirestore(), 'users');
									const newQuery = query(
										userCollection,
										where('email', '==', store.state.organizationUser.email)
									);

									getDocs(newQuery).then((snapshot) => {
										if (!snapshot.empty) {
											const userData = snapshot.docs[0].data();
											commit('setUser', userData);
											if (userData.isAdmin) {
												return resolve();
											} else if (userData.businessId) {
												const businessRef = doc(
													getOrganizationFirestore(),
													'business',
													userData.businessId
												);
												getDoc(businessRef)
													.then((businessSnapshot) => {
														const businessData = businessSnapshot.data();
														commit('setBusiness', businessData);
														return resolve();
													})
													.catch((error) => {
														console.log('error2', error.message);
														return reject(error.message);
													});
											} else {
												return reject('No Business User');
											}
										} else {
											return reject('No Business User');
										}
									})
									.catch((error) => {
										console.log('error3', error.message);
										return reject(error.message);
									});

									// const otherUserRef = doc(
									// 	getFirestore(otherFirestore),
									// 	'users',
									// 	store.state.organizationUser.appUid
									// );

									// console.log('org', store.state.organizationUser.appUid)

									// getDoc(otherUserRef)
									// 	.then((snapshot) => {
									// 		if (snapshot.exists()) {
									// 			console.log('org')
									// 			//THIS PERSON EXIST ... but on the other company so query this company for email
									// 			const userEmail = store.state.organizationUser.email;

									// 			const newQuery = query(
									// 				userCollection,
									// 				where('email', '==', userEmail)
									// 			);
									// 			console.log('Yeah we good...');
									// 			getDocs(newQuery).then((snapshot) => {
									// 				if (!snapshot.empty) {
									// 					const userData = snapshot.docs[0].data();
									// 					commit('setUser', userData);
									// 					if (userData.isAdmin) {
									// 						return resolve();
									// 					} else if (userData.businessId) {
									// 						const businessRef = doc(
									// 							getOrganizationFirestore(),
									// 							'business',
									// 							userData.businessId
									// 						);
									// 						getDoc(businessRef)
									// 							.then((businessSnapshot) => {
									// 								const businessData = businessSnapshot.data();
									// 								commit('setBusiness', businessData);
									// 								return resolve();
									// 							})
									// 							.catch((error) => {
									// 								console.log('error2', error.message);
									// 								return reject(error.message);
									// 							});
									// 					} else {
									// 						return reject('No Business User');
									// 					}
									// 				} else {
									// 					return reject('No Business User');
									// 				}
									// 			});
									// 		} else {
									// 			return reject('No Business User');
									// 		}
									// 	})
									// 	.catch((error) => {
									// 		console.log('error3', error.message);
									// 		return reject(error.message);
									// 	});
								})
								.catch((error) => {
									console.log('error4', error.message);
									return reject(error.message);
								});
						}
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else {
				return reject('No User');
			}
		});
	},
	showNotification({ commit }, data) {
		commit('setNotification', data);
	},
	removeNotification({ commit }) {
		commit('setNotification', null);
	},
	login({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			signInWithEmailAndPassword(auth, data.email, data.password)
				.then((response) => {
					dispatch('getUserData', response.user)
						.then(() => {
							return resolve();
						})
						.catch((error) => {
							return reject(error);
						});
				})
				.catch((error) => {
					commit('setError', error.message);
					reject(error);
				});
		});
	},
	logOut({ commit }) {
		return new Promise((resolve, reject) => {
			signOut(auth)
				.then(() => {
					commit('setUser', null);
					resolve();
				})
				.catch((error) => {
					commit('setError', error.message);
					reject(error);
				});
		});
	},
};

export default actions;
