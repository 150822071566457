<template>
	<side-bar
		:logo="isFullAdmin ? organization.appLogo : business.businessLogo"
		:title="isFullAdmin ? organization.appName : business.businessName"
	>
		<div slot="links">
			<div v-for="menuItem in menuItems" v-if="!menuItem.isComingSoon">
				<sidebar-item :link="menuItem">
					<div v-if="menuItem.children" v-for="children in menuItem.children">
						<sidebar-item :link="children" />
					</div>
				</sidebar-item>
			</div>
		</div>

		<div v-if="false" slot="links-after">
			<hr class="my-3 bg-light" />
			<h6 class="navbar-heading p-0 text-muted">Coming Soon</h6>

			<b-nav class="navbar-nav mb-md-3">
				<div v-for="menuItem in menuItems" v-if="menuItem.isComingSoon">
					<b-nav-item href="">
						<i :class="menuItem.icon"></i>
						<b-nav-text class="p-0 mr-1">{{ menuItem.name }}</b-nav-text>
					</b-nav-item>
				</div>
			</b-nav>
		</div>

		<div slot="links-after">
			<hr class="my-3 bg-light" />
			<h6 class="navbar-heading p-0 text-muted">Feedback & Resources</h6>

			<b-nav class="navbar-nav mb-md-3">
				<b-nav-item :href="DominionInformation.dominionWebsite">
					<i class="fas fa-question-circle"></i>
					<b-nav-text class="p-0">Dominion Support</b-nav-text>
				</b-nav-item>
				<b-nav-item :href="DominionInformation.dominionWebsite">
					<i class="fas fa-fire"></i>
					<b-nav-text class="p-0">Feature Request</b-nav-text>
				</b-nav-item>
				<b-nav-item :href="DominionInformation.dominionWebsite">
					<i class="fas fa-info-circle"></i>
					<b-nav-text class="p-0">Help & Resources</b-nav-text>
				</b-nav-item>
			</b-nav>
			<div class="d-flex align-items-center">
				<b-img
					class="mr-2"
					fluid
					:src="organization.appLogo"
					style="border-radius: 5px; height: 35px; border: 1px solid"
				/>
				<h6 class="navbar-heading p-0 text-muted my-3">
					{{ organization.appName }}
				</h6>
			</div>
		</div>
	</side-bar>
</template>
<script>
import { DashboardChildren } from '../../../routes/routesNames.js';
import { DominionInformation } from '../../../resources/DominionInformation.js';
import { mapGetters } from 'vuex';
import Badge from '../../../components/Badge.vue';

export default {
	name: 'sideMenu',
	computed: {
		...mapGetters({
			isFullAdmin: 'isFullAdmin',
			business: 'getBusiness',
			organization: 'getOrganization',
		}),
	},
	data() {
		return {
			DominionInformation,
			menuItems: [],
		};
	},
	mounted() {
		Object.values(DashboardChildren).forEach((entry) => {
			if (entry.id == DashboardChildren.home.id) {
				this.menuItems.push(entry);
			} else if (entry.icon && !entry.isHidden) {
				const meta = entry.meta;
				if (!this.isFullAdmin) {
					if (meta) {
						if (meta.isBusiness) {
							this.menuItems.push(entry);
						}
					}
				} else {
					if (meta) {
						if (!meta.isBusiness) {
							this.menuItems.push(entry);
						}
					} else {
						this.menuItems.push(entry);
					}
				}
			}
		});
	},
	components: { Badge },
};
</script>
