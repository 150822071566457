<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<MembersTable
						:isLoading="isLoading"
						:tableData="allUsers"
						:tableHeaders="userTitles"
						@generateQRCode="generateQRCode"
						@viewClicked="viewClicked"
						@deleteClicked="deleteClicked"
					/>
				</div>
			</div>
		</div>
		<div style="height: 200px;width: 200px;" class="pdf-container"></div>

	</div>
</template>
<script>
import PSPDFKit from 'pspdfkit'
import axios from 'axios'
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import {
	getOrganizationDatabase,
	getOrganizationFirestore,
} from '../../../resources/organizationFirebase';
import { mapActions, mapGetters } from 'vuex';
import { ref, get } from '@firebase/database';
import MembersTable from '../../Components/MemberViews/MembersTable.vue';
import { DashboardChildren } from '../../../routes/routesNames';
import { httpsCallable } from '@firebase/functions';
import { functions } from '../../../resources/firebase';
import { collection, getDocs } from '@firebase/firestore';

export default {
	name: 'shop',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeaderVue,
		MembersTable,
	},
	data() {
		return {
			isLoading: true,
			selectedQRCode: null,
			buttonItems: [
				{
					index: 0,
					title: 'Export Users',
					type: 'primary',
					isDisabled: true,
					icon: 'fas fa-download',
				},
			],
			activeWaiverId: null,
			allUsers: [],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.firstName + item.lastName;
					},
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true,
					tdClass: 'align-middle',
				},
				{
					key: 'username',
					label: 'Username',
					tdClass: 'align-middle',
				},
				{
					key: 'uid',
					label: 'Unique Id',
					tdClass: 'align-middle',
				},
				{
					key: 'member-actions',
					label: '',
					tdClass: 'align-middle',
				},
			],
		};
	},
	watch: {
		isLoading(val) {
			this.buttonItems[0].isDisabled = val;
		},
	},
	methods: {
		...mapActions(['showNotification']),
		async loadPDF(item){
			PSPDFKit.unload(".pdf-container");

			const instance = await PSPDFKit.load({
				headless: true,
				document: '/helpers/sample_badge.pdf',
				//licenseKey: 'pdf_live_vvruBUYLIgiohqw7swzQKHYQWp9tq4WHaIcOuPVi7PY'
			})

			

			const first_name_box = (await instance.search("first_name")).first().rectsOnPage.get(0);
  			const last_name_box = (await instance.search("last_name")).first().rectsOnPage.get(0);
			  const company_name_box = (await instance.search("company_name")).first().rectsOnPage.get(0);
			const qr_box = (await instance.search("qr_code")).first().rectsOnPage.get(0);

			  const first_name = new PSPDFKit.Annotations.TextAnnotation({
    boundingBox: first_name_box,
    fontSize: 16,
    text: {
		format: "plain",
		value: item.firstName
	},
    pageIndex: 0,
    fontColor: PSPDFKit.Color.BLACK,
    horizontalAlign: "center",
    backgroundColor: PSPDFKit.Color.WHITE,
  });

  const last_name = new PSPDFKit.Annotations.TextAnnotation({
    boundingBox: last_name_box,
    fontSize: 16,
    text: {
		format: "plain",
		value: item.lastName
	},
    pageIndex: 0,
    fontColor: PSPDFKit.Color.BLACK,
    horizontalAlign: "center",
    backgroundColor: PSPDFKit.Color.WHITE,
  });

  const email = new PSPDFKit.Annotations.TextAnnotation({
    boundingBox: company_name_box,
    fontSize: 10,
    text: {
		format: "plain",
		value: item.businessName ? item.businessName : item.email
	},
    pageIndex: 0,
    fontColor: PSPDFKit.Color.BLACK,
    horizontalAlign: "center",
    backgroundColor: PSPDFKit.Color.WHITE,
  });


  const request = await axios.get(item.qrCodePhotoURL, {responseType: "blob"});
const blob = await request.data
const imageAttachmentId = await instance.createAttachment(blob);
const annotation = new PSPDFKit.Annotations.ImageAnnotation({
  pageIndex: 0,
  isSignature: true,
  contentType: "image/png",
  imageAttachmentId,
  description: "QR Image",
  backgroundColor: PSPDFKit.Color.WHITE,
  boundingBox: new PSPDFKit.Geometry.Rect({
    left: qr_box.left - 33,
    top: qr_box.top - 30,
    width: 120,
    height: 120
  })
});
			
			// Add the annotations to the document.
  			await instance.create([first_name, last_name, email, annotation]);

			const flattenedDocument = await instance.exportPDF({ flatten: true });
			var blob2 = new Blob([flattenedDocument], {type: "application/pdf"});
        	var objectUrl = URL.createObjectURL(blob2);
        	window.open(objectUrl);

			

		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.checkIfExportAllowed();
			}
		},
		getUsers() {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'users');
			getDocs(firestoreRef).then((snapshot) => {
				if (!snapshot.empty) {
					snapshot.forEach((item) => {
						var user = item.data();
						this.allUsers.push(user);
						this.allUsers.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
					});
					this.isLoading = false;
				}
			});
		},
		checkIfExportAllowed() {
			/*
			const database = getOrganizationDatabase(this.organization);
			const reportsRef = ref(database, 'reports');
			get(reportsRef).then((snapshot) => {
				if (snapshot.exists()) {
					var previousReports = [];
					var todayDate = new Date();
					var todayMonth = todayDate.getMonth() + 1;
					var todayYear = todayDate.getFullYear();
					snapshot.forEach((item) => {
						const reportData = item.val();
						const reportDate = new Date(reportData.reportDate);
						if (
							reportDate.getMonth() + 1 == todayMonth &&
							reportDate.getFullYear() == todayYear
						) {
							previousReports.push(reportData);
						}
					});
					if (previousReports.length >= 3) {
						this.showNotification({
							type: 'danger',
							message: `You have exceeded the amount of reports for this month.`,
						});
					} else {
						this.exportUsers();
					}
				} else {
					this.exportUsers();
				}
			});
			*/
		},
		exportUsers() {
			this.isLoading = true;
			const exportUsers = httpsCallable(functions, 'exportUsers');
			exportUsers({
				organization: this.organization,
				filename: `Users_Export - ${this.$helpers.formatDate(
					new Date(),
					'yyyy-MM-dd'
				)}`,
			})
				.then((response) => {
					this.isLoading = false;
					if (response.data.downloadUrl) {
						window.open(response.data.downloadUrl, '_blank');
					}
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		generateQRCode(item){
			if (item.qrCodePhotoURL){
				this.loadPDF(item)
			}
		},
		viewClicked(item) {
			this.$router.push({
				name: DashboardChildren.memberProfile.name,
				query: {
					uid: item.uid,
				},
			});
		},
		deleteClicked(item) {
			console.log(`Moses delete on ${item}`);
		},
	},
	mounted() {
		//this.loadPDF()
		this.getUsers();
	},
};
</script>
