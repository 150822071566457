export const DominionInformation = {
	dominionName: 'Dominion Studio',
	dominionFullName: 'Dominion IT Consulting, LLC',
	dominionWebsite: 'https://www.dominionstudio.com',
	dominionEmail: 'info@dominionstudio.com',
	dominionStripePubKey:
		'pk_live_51HPBseHRR4QjYqaH1EOsQ0Ta7DPtIbVnVxEuaXcdgD49LljatKhvu89K1iPlNBEmN5H4DWG4mg6wY0T7C3CQkvyR00PD6OIpds',
	dominionStripeTestPubKey:
		'pk_test_51HPBseHRR4QjYqaHxtXTIlXrextYBuwipa6Lv3X655YPRtP0H8xmiKj9lFcXZiABl87QjTQdXPjr5qzOSv3c5UZh001xDb73KF',
	orgId: "dcVZPIWbcH4CsYA9Tdtt",
};

export const DominionTerminalFunctions = {
	getStripePaymentMethod:
		'https://us-central1-dominionterminal.cloudfunctions.net/getStripePaymentMethod',
	getTestStripePaymentMethod:
		'https://us-central1-dominionterminal.cloudfunctions.net/getTestStripePaymentMethod',
};

//export default { DominionInformation, DominionTerminalFunctions };
