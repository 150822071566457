<template>
	<div v-if="eventData">
		<DashboardHeader
			v-if="eventData"
			:title="`${eventData.eventTitle}`"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>

		<div v-if="eventData" class="container-fluid mt--6">
			<b-row>
				<b-col>
					<EditableEventCard
						:eventData="eventData"
						:allSpeakers="allSpeakers"
						@updateEvent="onUpdateEvent"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col> <ResourceCard :eventId="eventId" /> </b-col>
			</b-row>
		</div>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="yesClicked"
		/>
		<NewResource
			:headerTitle="buttonItems[0].children[this.newResourceId].title"
			:showModal="showResourceModal"
			:eventId="eventId"
			@close="showResourceModal = false"
			@newResource="onNewResource"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { getOrganizationFirestore } from '../../../../resources/organizationFirebase';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeader from '../../../mainViews/HeaderFooterComponents/DashboardHeader.vue';
import NewNotification from '../../../mainForms/NewNotification.vue';
import EditableEventCard from '../../../Components/EventViews/EditableEventCard.vue';
import BaseButton from '../../../../components/BaseButton.vue';
import Badge from '../../../../components/Badge.vue';
import Card from '../../../../components/Cards/Card.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import reoccuringOptions from '../../../../data/reoccuringOptions';
import BasicInformationCard from '../../../Components/EventViews/BasicInformationCard.vue';
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
} from '@firebase/firestore';
import NewResource from '../../../mainForms/NewResource.vue';
import ResourceCard from '../../../Components/VendorViews/ResourceCard.vue';

export default {
	name: 'eventView',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		eventId: {
			type: String,
			default: null,
		},
		eventStart: {
			type: [String, Number],
			default: null,
		},
	},
	components: {
		DashboardHeader,
		NewNotification,
		Card,
		EditableEventCard,
		BaseButton,
		Badge,
		QuestionModal,
		BasicInformationCard,
		NewResource,
		ResourceCard,
	},
	data() {
		return {
			isLoading: false,
			eventData: null,
			ticketsSold: 0,
			allSpeakers: [],
			reoccuringOptions,
			showNotificationModal: false,
			showQuestionModal: false,
			showResourceModal: false,
			modalQuestion: '',
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 250,
				addRemoveLinks: true,
				acceptedFiles: 'image/*',
			},
			selectedId: null,
			newResourceId: 0,
			sendNotificationId: 1,
			shareEventId: 2,
			cancelEventId: 3,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: this.newResourceId,
					title: 'Add New Resource',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.cancelEventId,
					title: 'Delete Event',
					type: 'danger',
				},
			];
		},
		getEvent() {
			const firestore = getOrganizationFirestore(this.organization);
			const eventRef = doc(firestore, `events/${this.eventId}`);
			getDoc(eventRef).then((snapshot) => {
				if (snapshot.exists()) {
					this.eventData = snapshot.data();
				}
			});
		},
		getSpeakers() {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'speakers');
			getDocs(firestoreRef).then((snapshot) => {
				if (!snapshot.empty) {
					const total = snapshot.size;
					var counter = 0;
					snapshot.forEach((speaker) => {
						const speakerData = speaker.data();
						this.allSpeakers.push(speakerData);
						if (counter == total - 1) {
							this.getEvent();
						}
						counter += 1;
					});
				} else {
					this.getEvent();
				}
			});
		},
		askDeleteQuestion() {
			this.modalQuestion =
				'Are you sure you want to delete this event? (This action cannot be undone)';
			this.showQuestionModal = true;
		},
		yesClicked() {
			if (this.selectedId == this.cancelEventId) {
				this.deleteEvent();
			}
		},
		deleteEvent() {
			const firestore = getOrganizationFirestore(this.organization);
			const ref = doc(firestore, `events/${this.eventId}`);
			deleteDoc(ref)
				.then(() => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: `${this.eventData.eventTitle} has been deleted.`,
					});
					this.navigateToSchedule();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		onNewResource(newFile) {
			this.getEvent();
		},
		onUpdateEvent(data) {
			console.log('Update Event On HOme Page');
		},
		getReoccurrenceDBDate(data) {
			var reoccurrenceDate = new Date(data.eventStart); //this.reoccurrenceDate;
			reoccurrenceDate.setHours(0, 0, 0, 0);
			return reoccurrenceDate;
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			switch (childClicked.index) {
				case this.newResourceId:
					this.showResourceModal = true;
					break;
				case this.sendNotificationId:
					//this.showNotificationModal = true;
					break;
				case this.shareEventId:
					this.shareEvent();
					break;
				case this.cancelEventId:
					this.askDeleteQuestion();
					break;
				default:
					break;
			}
		},
		navigateToSchedule() {
			this.$router.replace({
				name: DashboardChildren.schedule.name,
			});
		},
	},
	mounted() {
		if (!this.eventId) {
			this.navigateToSchedule();
		} else {
			this.getSpeakers();
			this.setButtons();
			//Dates: yyyy-MM-ddThh:mm
		}
	},
};
</script>
<style>
.dropzone-custom-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
</style>
