<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<SpeakerTable
						:isLoading="isLoading"
						:tableData="allSpeakers"
						:tableHeaders="userTitles"
						@removeClicked="removeClicked"
						@editClicked="editClicked"
					/>
				</div>
			</div>
		</div>
		<NewSpeaker
			:showModal="showModal"
			:editSpeakerData="editSpeakerData"
			@close="resetSpeaker()"
			@newSpeaker="onNewSpeaker"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="resetSpeaker()"
			@noClicked="resetSpeaker()"
			@yesClicked="removeSpeaker()"
		/>
	</div>
</template>
<script>
import { collection, deleteDoc, doc, getDocs } from '@firebase/firestore';
import { deleteObject, ref } from '@firebase/storage';
import { mapActions, mapGetters } from 'vuex';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../../resources/organizationFirebase';
import SpeakerTable from '../../Components/SpeakerViews/SpeakerTable.vue';
import NewSpeaker from '../../mainForms/NewSpeaker.vue';
import DashboardHeader from '../HeaderFooterComponents/DashboardHeader.vue';
export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: { DashboardHeader, SpeakerTable, NewSpeaker, QuestionModal },
	data() {
		return {
			isLoading: true,
			showModal: false,
			showQuestionModal: false,
			modalQuestion: null,
			buttonItems: [
				{
					index: 0,
					title: 'New Speaker',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
			allSpeakers: [],
			editSpeakerData: null,
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: 'auto' },
					sortByFormatted: (value, key, item) => {
						return item.speakerName;
					},
				},
				{
					key: 'speakerBio',
					label: 'Bio',
					sortable: false,
					thStyle: { width: '60%' },
					tdClass: 'align-middle',
				},
				{
					key: 'actions',
					label: '',
					tdClass: 'align-middle',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.editSpeakerData = null;
				this.showModal = true;
			}
		},
		onNewSpeaker(speakerData) {
			var speakerGotData = this.allSpeakers.find(
				(e) => e.speakerId == speakerData.speakerId
			);
			if (speakerGotData) {
				this.getSpeakers();
			} else {
				this.allSpeakers.push(speakerData);
			}

			this.showNotification({
				type: 'success',
				message: `${speakerData.speakerName} has been added.`,
			});
		},
		getSpeakers() {
			this.allSpeakers = [];
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'speakers');
			getDocs(firestoreRef).then((snapshot) => {
				if (!snapshot.empty) {
					snapshot.forEach((item) => {
						var speaker = item.data();
						this.allSpeakers.push(speaker);
					});
					this.isLoading = false;
					this.allSpeakers.sort(function (a, b) {
						return a.speakerName.localeCompare(b.speakerName);
					});
				}
			});
		},
		resetSpeaker() {
			this.editSpeakerData = null;
			this.showQuestionModal = false;
			this.showModal = false;
		},
		removeClicked(speakerData) {
			this.editSpeakerData = speakerData;
			this.modalQuestion = `Are you sure you want to remove ${speakerData.speakerName}?`;
			this.showQuestionModal = true;
		},
		removeSpeaker() {
			const firestore = getOrganizationFirestore(this.organization);
			const storage = getOrganizationStorage(this.organization);
			const speakerRef = doc(
				firestore,
				`speakers/${this.editSpeakerData.speakerId}`
			);
			const speakerStoreRef = ref(
				storage,
				`speakers/${this.editSpeakerData.speakerId}`
			);

			deleteObject(speakerStoreRef)
				.finally(() => {
					deleteDoc(speakerRef)
						.then(() => {
							this.getSpeakers();
							this.showNotification({
								type: 'success',
								message: `Speaker has been deleted.`,
							});
						})
						.catch((error) => {
							this.showNotification({
								type: 'danger',
								message: error.message,
							});
						});
				})
				.catch((error) => {
					console.log(error.message);
				});
		},
		editClicked(speakerData) {
			this.editSpeakerData = speakerData;
			this.showModal = true;
			console.log('edit: ', speakerData.speakerName);
		},
	},
	mounted() {
		this.getSpeakers();
	},
};
</script>
